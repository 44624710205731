import { Grid, styled } from '@mui/material';

const StyledImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

export default function ImageSide() {
  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{ height: { xs: '30vh', md: '100%' }, overflow: 'hidden' }}
    >
      <StyledImage src='/login1.jpg' alt='Sign up visual representation' />
    </Grid>
  );
}
