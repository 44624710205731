import {
  Stack,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Typography,
  Alert,
  styled,
} from '@mui/material';
import { useState } from 'react';
import TextField from '../Inputs/TextField/FormikTextField';
import Link from 'next/link';

const SignInFormContainer = styled(Stack)(({ theme }) => ({
  width: '100%', // Allow it to grow or shrink as needed
  [theme.breakpoints.up('sm')]: {
    maxWidth: '400px', // Set a maximum width
  },
}));

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  // [theme.breakpoints.up('sm')]: {
  //   flexDirection: 'row',
  //   justifyContent: 'space-between',
  // },
}));

interface SignInFormProps {
  errors: any;
  isSubmitting: boolean;
}

export default function SignInForm({ errors, isSubmitting }: SignInFormProps) {
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowPassword(e.target.checked);
  };

  return (
    <SignInFormContainer spacing={3}>
      <Box>
        <Typography
          variant='h2'
          sx={{ fontSize: '28px', fontWeight: '700', mb: 1 }}
        >
          Sign in
        </Typography>

        <Typography sx={{ color: '#6e6d7a' }}>
          Please enter your details
        </Typography>

        {errors?.form ? (
          <Alert sx={{ mt: 1 }} severity='error'>
            {errors?.form}
          </Alert>
        ) : null}
      </Box>

      <TextField name='username' size='small' label='Username or email' />

      <TextField
        type={showPassword ? 'text' : 'password'}
        name='password'
        size='small'
        label='Password'
      />

      <FlexBox>
        <Box sx={{ display: 'block' }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={showPassword}
                onChange={handleShowPasswordChange}
              />
            }
            sx={{ userSelect: 'none' }}
            label='Show password'
          />
        </Box>

        <Link
          href='/auth/forgot-password'
          style={{ textDecoration: 'underline', color: '#0d0c22' }}
        >
          Forgot password?
        </Link>
      </FlexBox>

      <Button
        fullWidth
        type='submit'
        sx={{ fontWeight: '600' }}
        variant='contained'
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Signing in...' : 'Sign In'}
      </Button>

      <Typography sx={{ textAlign: 'center' }}>
        {`Don't`} have an account?{' '}
        <Link
          href='/auth/sign-up'
          style={{ textDecoration: 'none', color: '#3d3d4e' }}
        >
          <span style={{ textDecoration: 'underline', color: '#0d0c22' }}>
            Sign Up
          </span>
        </Link>
      </Typography>
    </SignInFormContainer>
  );
}
