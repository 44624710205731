/**
 *
 * Accounts URLs
 */
const ACCOUNTS_HOST = process.env.NEXT_PUBLIC_ACCOUNTS_HOST;

export const getAccountsURL = ({ query = '' } = {}) => {
  return `${ACCOUNTS_HOST}/api/accounts/${query}`;
};

export const getTokenV2URL = ({ query = '' } = {}) => {
  return `${ACCOUNTS_HOST}/api/v2/token/${query}`;
};

export const getTokenRefreshURL = ({ query = '' } = {}) => {
  return `${ACCOUNTS_HOST}/api/token-refresh/${query}`;
};

export const getForgotPasswordURL = ({ query = '' } = {}) => {
  return `${ACCOUNTS_HOST}/api/accounts/password-reset/${query}`;
};

export const getResetPasswordURL = ({ query = '' } = {}) => {
  return `${ACCOUNTS_HOST}/api/accounts/password-reset/confirm/${query}`;
};
