import React from 'react';
import axios, { AxiosError } from 'axios';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { parseCookies, setCookie } from 'nookies';
import PageWrapper from '@repo/ui/Auth/PageWrapper';
import SignInForm from '@repo/ui/Auth/SignInForm';
import { getTokenV2URL } from '@repo/config/apps/accounts/routes';
import { useMutation } from '@tanstack/react-query';
import {
  destroyTokens,
  validateAndDecodeUser,
  validateServerSideToken,
} from '@repo/config/authUtils';
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_REFRESH_TOKEN,
  COOKIE_USER_SESSION,
} from '@repo/config/constants';

type Credentials = {
  username: string;
  password: string;
  domain?: string;
};

export async function getServerSideProps(ctx: any) {
  const cookies = parseCookies(ctx);
  const accessToken = cookies[COOKIE_ACCESS_TOKEN];
  const refreshToken = cookies[COOKIE_REFRESH_TOKEN];
  const userSessionDetails = cookies[COOKIE_USER_SESSION];

  const { isValid } = validateAndDecodeUser(
    accessToken,
    refreshToken,
    userSessionDetails
  );

  if (isValid) {
    const { isTokenValidOnServer } = await validateServerSideToken(accessToken);

    if (isTokenValidOnServer) {
      return {
        redirect: {
          destination: '/',
          permanent: false,
        },
      };
    }

    destroyTokens();
  }

  return {
    props: {},
  };
}

export default function SignIn() {
  const mutation = useMutation({
    mutationFn: (credentials: Credentials) => {
      return axios.post(getTokenV2URL(), credentials);
    },
  });

  return (
    <PageWrapper>
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={Yup.object({
          username: Yup.string()
            .min(3, 'Invalid username or email')
            .required('Invalid username or email'),
          password: Yup.string()
            .min(8, 'Invalid password')
            .required('Invalid password'),
        })}
        onSubmit={async (
          { username, password },
          { setSubmitting, resetForm, setFieldError }
        ) => {
          setSubmitting(true);
          setFieldError('form', '');

          try {
            const origin = window?.location?.origin;
            const domain = origin.startsWith('http://localhost')
              ? process.env.NEXT_PUBLIC_APP_HOST
              : origin;

            const values = {
              username,
              password,
              domain,
            };

            const { data } = await mutation.mutateAsync(values);

            const cookieOptions = {
              maxAge: 30 * 24 * 60 * 60,
              path: '/',
            };

            const userDetails = data?.user_details;

            const user = {
              userId: userDetails?.id,
              username: userDetails?.username,
              email: userDetails?.email,
              isSuperUser: userDetails?.is_superuser,
              personId: userDetails?.details?.person?.id,
              client: userDetails?.client,
              roleNames: userDetails?.roles_names,
              employeeId: userDetails?.details?.employee?.id,
              employeeSupervisorId:
                userDetails?.details?.employee?.department_heads?.id,
              employeeSupervisorName:
                userDetails?.details?.employee?.department_heads?.label,
            };

            console.log('user', JSON.stringify(user, null, 2));

            const accessToken = data?.auth?.access;
            const refreshToken = data?.auth?.refresh;
            const userString = JSON.stringify(user);

            setCookie(null, COOKIE_USER_SESSION, userString, cookieOptions);
            setCookie(null, COOKIE_ACCESS_TOKEN, accessToken, cookieOptions);
            setCookie(null, COOKIE_REFRESH_TOKEN, refreshToken, cookieOptions);

            resetForm();
            window.location.href = '/';
          } catch (error) {
            if (error instanceof AxiosError) {
              setFieldError('form', error?.response?.data?.detail);
            } else {
              setFieldError('form', 'Something went wrong.');
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, isSubmitting }) => (
          <Form style={{ width: '100%' }}>
            <SignInForm errors={errors} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </PageWrapper>
  );
}
