import { Grid, styled } from '@mui/material';
import ImageSide from './ImageSide';

const StyledSignInContainer = styled(Grid)(() => ({
  height: '100vh',
}));

const StyledFormArea = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0, 'auto'), // Center it horizontally
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(8),
    paddingLeft: theme.spacing(14),
  },
}));

interface PageWrapperProps {
  children?: React.ReactNode;
}

export default function PageWrapper({ children }: PageWrapperProps) {
  return (
    <StyledSignInContainer container>
      <ImageSide />
      <StyledFormArea item xs={10} sm={7}>
        {children}
      </StyledFormArea>
    </StyledSignInContainer>
  );
}
